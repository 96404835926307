import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public menu: any =[{id:1 ,icon:'stats-chart', name:'Dashboard' , path: 'menu'},
  // {id:2 ,icon:'map', name:'Property' , path: 'property'},
  {id:3 ,icon:'person', name:'Profile' , path: 'profile'},
  {id:5 ,icon:'log-out', name:'Logout' , path: 'login'},] 


  clickMenuItem : any;

  constructor() {}
}
