import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'add-mr',
    loadChildren: () => import('./pages/add-mr/add-mr.module').then( m => m.AddMrPageModule)
  },
  {
    path: 'manage-doctor',
    loadChildren: () => import('./pages/manage-doctor/manage-doctor.module').then( m => m.ManageDoctorPageModule)
  },
  {
    path: 'add-doctor',
    loadChildren: () => import('./pages/add-doctor/add-doctor.module').then( m => m.AddDoctorPageModule)
  },
  {
    path: 'manage-city',
    loadChildren: () => import('./pages/manage-city/manage-city.module').then( m => m.ManageCityPageModule)
  },
  {
    path: 'aproval-mtp',
    loadChildren: () => import('./pages/aproval-mtp/aproval-mtp.module').then( m => m.AprovalMtpPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'model',
    loadChildren: () => import('./pages/model/model.module').then( m => m.ModelPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'aproval-dcr',
    loadChildren: () => import('./pages/aproval-dcr/aproval-dcr.module').then( m => m.AprovalDcrPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
